import React from 'react'
import Layout from '../../components/layout'
import { LongWritingCreator } from '../../components/ChallengeCreators'

const LongWritingCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <LongWritingCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default LongWritingCreatorPage
